import GlobalLayout from "components/page/GlobalLayout";
import SensorSpecsPage from "components/sensors/SensorSpecsPage";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function B10() {
  const renderContent = data => {
    const sensor = {
      title: "B10",
      imageHeader: data.b10Header,
      imageHeaderMobile: data.b10HeaderMobile,
      image: data.b10Dims,
      objectPosition: "90%",
      description:
        "Get one-touch emergency response: program button presses with a correlated action, like dispatching emergency services, triggering loud alarms, or securing doors.",
      pdf: "/files/specs/B10.pdf",
      column1: [
        {
          title: "Dimensions",
          text: "70mm (2.75in) x 32.2mm (1.26in) x 18.2mm (0.72in)",
        },
        {
          title: "Range",
          text: "Up to 100m (328ft)",
        },
        {
          title: "Battery Type",
          text: "Replaceable 2x1.5V AAA (Lithium Batteries included)",
        },
        {
          title: "Battery Life",
          text: "5 years with typical medium-heavy use**",
        },
        {
          title: "Weight",
          text: "32.4g (w/ battery)",
        },
      ],
      column2: [
        {
          title: "Detection/Function",
          text:
            "Supports short-press, long-press, and double-press button functions",
        },
        {
          title: "Mounting",
          text:
            "Two-sided tape (included) or screws (with required mounting cover)",
        },
        {
          title: "Weather Resistance",
          text: "IPX4",
        },
        {
          title: "NDAA, FCC, CE, REACH, RoHS, IC, and RCM Compliant",
        },

        {
          title: "2-Year Warranty Included",
        },
        {
          title: "",
          text: "**Subject to change",
        },
      ],
    };

    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>Rhombus B10 Smart Button - One-Touch Emergency Response</title>
          <meta
            name="description"
            content="Stay in control in a crisis. Use the B10 Smart Button to dispatch emergency services, trigger loud alarms, or secure doors."
          />
          <meta
            name="thumbnail"
            content="https://rhombus.com/img/b10-beauty-shot.jpg"
          />
        </Helmet>
        <SensorSpecsPage sensor={sensor} />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      b10Header: file(
        relativePath: { eq: "components/sensors/img/b10-header-2000-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      b10HeaderMobile: file(
        relativePath: {
          eq: "components/sensors/img/b10-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      b10Dims: file(
        relativePath: { eq: "components/sensors/img/b10-dims-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
